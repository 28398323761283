<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="销售分析"></nav-bar>
    </template>
    <div class="unselectable">
      <!-- top -->
      <div class="top-content">
        <div class="text-right total-price">
          {{ currentData.sumMoney | currency }}
        </div>
        <div class="clearfix cell-item">
          <div class="pull-left time-cell-item">
            <div class="time-picker" @click="dateDialog = true">
              <i style="color: #333;font-size: 16px" class="iconfont2 icon-rili"></i>
              <div class="current-time">{{ showDateTxt }}</div>
            </div>
          </div>
          <div class="text-color gray pull-right">销售总额</div>
        </div>
      </div>
      <!-- chart -->
      <div class="chart-container">
        <canvas id="myChart" class="chart-box" ref="myChart"></canvas>
      </div>
      <!-- 列表部分 -->
      <div class="sale-content">
        <div class="sale__top">
          <div class="sale__title"> 热销商品 Top 10</div>
          <div class="sale__filter">
            <van-dropdown-menu active-color="#ff6a3c">
              <van-dropdown-item @change="handleSort" v-model="sortType" :options="sortOptions" />
            </van-dropdown-menu>
          </div>
        </div>
        <div class="sale">
          <template v-if="saleData.length">
            <div class="sale__item" :key="item.rankId" v-for="(item, index) in saleData">
              <div class="item__index">{{ index + 1 }}</div>
              <div class="item__info van-hairline--bottom">
                <div class="goods__img van-hairline--surround">
                  <van-image width=" 9.33333vw" radius="4" height=" 9.33333vw" fit="cover" :src="imgFullFix(item.imgUrl, 'small')" />
                </div>
                <div class="goods__info">
                  <div class="goods__title van-ellipsis">{{ item.goodsName }}</div>
                  <div class="goods__num">x {{ item.goodsQuantity }}</div>
                </div>
                <div class="goods__price">
                  {{ item.totalMoney | currency }}
                </div>
              </div>
            </div>
          </template>
          <van-empty v-else description="本月暂无销售记录哦" />
        </div>
      </div>
    </div>
    <template v-solt:other>
      <van-popup position="bottom" get-container="body" v-model="dateDialog">
        <van-datetime-picker
          @confirm="handleDateTimeConfirm"
          @cancel="dateDialog = false"
          v-model="currentDate"
          type="year-month"
          :max-date="maxDate"
          :formatter="formatter"
        />
      </van-popup>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import F2 from '@antv/f2'
import FormModel from '@/model/analyze-chart/index'
import dayjs from 'dayjs'
import {
  DatetimePicker,
  Icon,
  Empty,
  Image,
  DropdownMenu,
  DropdownItem,
  Popup,
  Toast
} from 'vant'
import vue from 'vue'
import { imgFullFix } from '@/utils/img'

export default {
  name: 'AnalyzeChart',
  data () {
    const formModel = new FormModel()
    const numberToCurrency = vue.filter('numberToCurrency')
    return {
      dateDialog: false,
      numberToCurrency,
      formModel,
      maxDate: new Date(),
      dateTimeVal: new Date(), // 默认日期
      currentDate: new Date(),
      monthData: [],
      chart: null,
      defaultColor: '#ffffff',
      themeColor: '#ff7730',
      saleData: [],
      totalMoney: null,
      defaultTime: [2000, 1],
      sortType: 'totalMoney',
      sortOptions: [
        { text: '按销售金额排序', value: 'totalMoney' },
        { text: '按销售数量排序', value: 'goodsQuantity' }
      ]
    }
  },
  components: {
    PageView,
    NavBar,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Empty.name]: Empty,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Toast.name]: Toast
  },
  created () {
  },
  async mounted () {
    this.initChartModel()
    await this.formModel.getRegisterTime()
  },
  beforeDestroy () {
  },
  methods: {
    imgFullFix,
    formatter (type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      }
      return val
    },
    handleDateTimeConfirm (value) {
      this.dateTimeVal = value
      this.dateDialog = false
      this.initChartModel()
    },
    // 初始化销售分析
    async initChartModel () {
      await this.initAnalyze()
      this.initChartData()
      this.handleSort('totalMoney') // 默认选中金额排序
    },
    // 初始化图表数据
    initChartData () {
      this.initChart()
      this.setTheme()
      this.clearTitle()
      this.setAxis()
      this.changeInterval()
      this.toolTip()
      this.renderData(this.monthData)
    },
    // 获取分析数据
    initAnalyze () {
      return new Promise((resolve, reject) => {
        if (this.dateTimeVal !== '') {
          return this.formModel.getSalesAnalyze(this.dateTimeVal).then(res => {
            this.monthData = res.monthData
            this.saleData = res.saleData
            resolve(true)
          }).catch(() => {
            Toast('获取失败')
          })
        }
      })
    },
    handleSort (value) {
      this.saleData.sort((a, b) => {
        return b[value] - a[value]
      })
    },
    // 初始化图表
    initChart () {
      this.chart = new F2.Chart({
        id: 'myChart',
        pixelRatio: window.devicePixelRatio // 指定分辨率
      })
    },
    // 设置图表主题
    setTheme () {
      F2.Global.setTheme({
        guide: {
          line: {
            stroke: this.themeColor,
            opacity: '0.5',
            lineWidth: 2
          },
          grid: {
            stroke: this.themeColor,
            fill: this.themeColor,
            opacity: '0.5'
          }
        }
      })
    },
    // 不显示图例说明
    clearTitle () {
      this.chart.legend(false)
    },
    // 设置坐标
    setAxis () {
      this.chart.axis('monthDate', {
        line: { stroke: 'transparent' },
        grid: {
          opacity: '.2'
        },
        label: (text, index, total) => {
          const cfg = {
            textAlign: 'center',
            fontSize: 12
          }
          cfg.text = text + '月'
          return cfg
        }
      })
      this.chart.axis('sumMoney', {
        line: { stroke: 'transparent' },
        grid: {
          opacity: '.3'
        },
        labelOffset: 20,
        label: (text, index, total) => {
          const cfg = {
            textAlign: 'center',
            fill: '#fff',
            fontSize: 12,
            opacity: '0.5'
          }
          if (index === 0) {
            cfg.fontSize = 0
          }
          cfg.text = this.numberToCurrency(text) // cfg.text 支持文本格式化处理
          return cfg
        }
      })
    },
    // 修改柱形颜色
    changeInterval () {
      this.chart.interval().position('monthDate*sumMoney').color('isChecked', (isChecked) => {
        if (isChecked) {
          return this.defaultColor
        }
        return this.themeColor
      })
    },
    // 显示提示信息
    toolTip () {
      this.chart.tooltip({
        showItemMarker: false,
        onShow: function (ev) {
          const { items } = ev
          items[0].name = null
          items[0].name = items[0].title + '月'
          items[0].value = items[0].origin.sumMoney + '元'
        }
      })
    },
    // 渲染数据
    renderData (data) {
      this.chart.source(data)
      this.chart.render()
    }
  },
  computed: {
    // 当前月图表数据
    currentData () {
      const data = this.monthData.filter(item => {
        if (item.isChecked) return item
      })
      return data[0] || {}
    },
    showDateTxt () {
      if (dayjs(this.dateTimeVal).isSame(new Date(), 'month')) {
        return '本月'
      } else {
        return dayjs(this.dateTimeVal).format('YYYY-MM')
      }
    }
  }
}
</script>
