import * as urls from '@/config/net'
import api from '@/tools/api-tools'
import { defaultGoodsImg, imgCDNPath } from '@/utils/img'
import dayjs from 'dayjs'

class TableData {
  constructor (data) {
    this.monthDate = data.monthDate
    this.sumMoney = data.sumMoney
    this.isChecked = data.isChecked
  }
}

class SalesTop {
  constructor (data) {
    this.rankId = data.rankId
    this.goodsName = data.goodsName
    this.goodsQuantity = data.goodsQuantity
    this.totalMoney = data.totalMoney
    this.imgUrl = data.imgUrl
  }
}

class AnalyzeItem {
  constructor () {
    this.dateTimeVal = '2018-03-09'
    this.startTime = 2000
    this.endTime = dayjs().year()
  }

  // 格式化时间
  formatTime (time) {
    return dayjs(time).format('YYYY-MM-DD')
  }

  // 格式化销售top
  _schemaSalesTop (data) {
    const list = []
    for (const item of data) {
      list.push(new SalesTop({
        rankId: item.rankId,
        goodsName: item.goodsName,
        goodsQuantity: item.goodsQuantity,
        totalMoney: item.totalMoney,
        imgUrl: imgCDNPath(item.picUrl ? item.picUrl : defaultGoodsImg)
      }))
    }
    return list
  }

  // 格式化图表数据
  _schemaTableData (data) {
    const list = []
    for (const item of data) {
      list.push(new TableData({
        monthDate: (dayjs(item.monthDate).month() + 1).toString(),
        sumMoney: item.sumMoney,
        isChecked: item.isChecked
      }))
    }
    return list
  }

  // 获取销售分析数据
  getSalesAnalyze (time) {
    const url = `${urls.apiUrl}/sales/mobiletopsale`
    return api.post(url, { date: this.formatTime(time) }).then(res => {
      const data = res.data || []
      const monthData = this._schemaTableData(data.monthData)
      const saleData = this._schemaSalesTop(data.topSales)
      return { monthData, saleData }
    })
  }

  // 获取用户注册时间
  getRegisterTime () {
    const url = `${urls.apiUrl}/account/current-account-info`
    return api.get(url).then(res => {
      const data = (res.data || {}).registerTime
      this.startTime = dayjs(this.formatTime(data)).year()
    })
  }
}

export default AnalyzeItem
